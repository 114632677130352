.container {
    margin: 0 4em;
}

.header img {
    width: 100%;
    height: auto;
}

.jobFilterCard {
    margin: 40px 0;
    margin-top: -40px;
    padding: 32px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    box-shadow: 0 0 24px 1px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    transition: 0.4s;
}

.filterAlt {
    color: hsl(180, 29%, 50%);
}

.filteredItems {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.clear {
    color: hsl(180, 29%, 50%);
}

.clear:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (max-width: 375px),
(min-width:375px) and (max-width: 768px) {
    .container {
        margin: 0 1.5em;
    }

    .jobFilterCard {
        display: flex;
        gap: 16px;
        margin: 70px 0px;
        margin-top: -40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .container {
        margin: 0 3em;
    }
}

@media only screen and (min-width: 1024px) and (min-width: 1400px) {
    .container {
        margin: 0 6em;
    }
}