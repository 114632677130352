.card {
    border-left: 4px solid transparent;
    display: flex;
    align-items: center;
    margin: 40px 0;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0 0 24px 1px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    transition: 0.4s;
}

.card:hover {
    border-left: 4px solid hsl(180, 8%, 52%);
    box-shadow: 0 0 32px 1px rgba(0, 0, 0, 0.12);
}

.logo img {
    width: 72px;
    height: auto;
}

.jobDetailsSec {
    display: flex;
    flex: 1;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}

.jobDetails {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 20px;
}

.postingDetails {
    display: flex;
    align-items: center;
    gap: 8px;
    color: hsl(180, 29%, 50%);
}

.newJob,
.featured {
    padding: 4px 8px;
    border-radius: 16px;
    color: hsl(180, 31%, 95%);
}

.newJob {
    background-color: hsl(180, 29%, 50%);
}

.featured {
    background-color: hsl(180, 14%, 20%);
}

.position {
    font-weight: 700;
    color: hsl(180, 14%, 20%);
}

.position:hover {
    color: hsl(180, 29%, 50%);
    cursor: pointer;
}

.type {
    color: hsl(180, 8%, 52%);
    display: flex;
    align-items: center;
}

.hrLine {
    width: 100%;
    border-top: 2px solid hsl(180, 29%, 50%);
    display: none;
}

.skills {
    display: flex;
    flex-wrap: wrap;
    place-items: flex-end;
}

.skill {
    margin: 4px;
    padding: 6px;
    border-radius: 4px;
    color: hsl(180, 29%, 50%);
    background-color: hsl(180, 31%, 95%);
}

.skill:hover {
    color: hsl(180, 31%, 95%);
    background-color: hsl(180, 29%, 50%);
    cursor: pointer;
}

@media only screen and (max-width: 375px),
(min-width:375px) and (max-width: 768px) {
    .card {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        margin: 70px 0px;
    }

    .logo img {
        width: 64px;
        height: auto;
        margin-top: -56px;
        margin-bottom: 12px;
    }

    .jobDetailsSec {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        place-items: flex-start;
    }

    .jobDetails {
        margin-left: 0px;
    }

    .hrLine {
        display: block;
    }
}