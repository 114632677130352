.itemCard {
    display: flex;
}

.item {
    padding: 8px;
    color: hsl(180, 29%, 50%);
    background-color: hsl(180, 31%, 95%);
    border-radius: 3px 0 0 3px;
}

.close {
    padding: 8px 12px;
    color: hsl(180, 31%, 95%);
    background-color: hsl(180, 29%, 50%);
    border-radius: 0 3px 3px 0;
    cursor: pointer;
}

.close:hover {
    background-color: hsl(180, 14%, 20%);
}