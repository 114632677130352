.container {
    margin: 0 4em;
}

.header img {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 375px),
(min-width:375px) and (max-width: 768px) {
    .container {
        margin: 0 1.5em;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .container {
        margin: 0 3em;
    }
}

@media only screen and (min-width: 1024px) and (min-width: 1400px) {
    .container {
        margin: 0 6em;
    }
}